import React, { FC } from 'react';
import '../Blog.scss';
import LargeCompanyContainer from '../../../components/pagesComponents/MainPage/LargeCompanyContainer';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { Link } from 'gatsby';
import BlogLayout from '../../../components/PagesLayouts/BlogLayouts';
import spectar from '../../../assets/img/companies/spectar-black.svg';
import statflo from '../../../assets/img/companies/statflo-black.svg';
import walmart from '../../../assets/img/companies/walmart-black.svg';
import freshii from '../../../assets/img/companies/freshii-black.svg';

const blogStudies: FC = () => {
    // useScrollToTop();
    return (
        <BlogLayout>
            <div className="blog__cards-wrapper">
                <Link to="/blog/unlocking-the-power-of-managed-service/">
                    <div className="blog__card">
                        <Link to="/spectar-case-study/">
                            <LargeCompanyContainer
                                color="dark"
                                img={spectar}
                                typeBG="solid-bg"
                                description="Building a pioneering AR product for construction"
                                label="CASE STUDY"
                                labelType="editorial"
                                hashtags="Microservice architecture, Microsoft HoloLens 2, REST API, Web development, +13 more"
                                size="blog"
                            />
                        </Link>
                    </div>
                </Link>
                <Link to="/statflo-case-study/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            color="dark"
                            typeBG="solid-bg"
                            img={statflo}
                            description="Entirely rebuilt 1:1 SMS outreach SaaS product for extra profitable retail platform"
                            label="CASE STUDY"
                            hashtags="Microservice architecture, Microsoft HoloLens 2, REST API, Web development, +13 more"
                            labelType="editorial"
                            size="blog"
                        />
                    </div>
                </Link>
            </div>
            <div className="blog__cards-wrapper">
                <Link to="/walmart-case-study/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            color="dark"
                            img={walmart}
                            description="Increasing Walmart weekly ads conversion with a custom web app for Ad Tech company"
                            label="CASE STUDY"
                            hashtags="Amazon, Retail & E-commerce, React, Web development, +13 more"
                            typeBG="solid-bg"
                            labelType="editorial"
                            size="blog"
                        />
                    </div>
                </Link>
                <Link to="/freshii-case-study/">
                    <div className="blog__card">
                        <LargeCompanyContainer
                            color="dark"
                            img={freshii}
                            typeBG="solid-bg"
                            description="Modernize the Technology Layer in the Restaurant Tech Domain"
                            label="CASE STUDY"
                            labelType="editorial"
                            hashtags="Mobileapp, Restaurant Technologies, Web development, +13 more"
                            size="blog"
                        />
                    </div>
                </Link>
            </div>
        </BlogLayout>
    );
};

export default blogStudies;
